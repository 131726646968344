import React from "react"

import Seo from "../shared_components/seo"

import "@fortawesome/fontawesome-free/css/all.css"
import { createBrowserHistory } from "history";

if (typeof window !== 'undefined') {
  var history = createBrowserHistory();
}

var contentSpacerStyle = {
  backgroundColor: 'white',
  marginTop: '6px',
  padding: '10px',
};

class Tooltip extends React.Component {
  render() {
    var tooltipContainerStyle = Object.assign(this.props.style, {
      position: 'absolute',
      left: '' + this.props.pos.x + '%',
      top: '' + this.props.pos.y + '%',
    });
    var tooltipStyle = {
      background: 'white',
      position: 'relative',
      left: '-130px',
      top: '0px',
      width: '260px',
      textAlign: 'center',
      borderRadius: '10px',
      boxShadow: '0 2px 10px 1px black'
    };
    var tooltipTextStyle = {
      padding: '10px',
      userSelect: 'text',
    };
    return (
      <div style={tooltipContainerStyle}>
        <div style={tooltipStyle}>
          <div style={tooltipTextStyle}>
            {this.props.text}
          </div>
        </div>
      </div>
    )
  }
}

var Region = function(regionClick, iconPos, icon, regionId, tooltipPos, text, background) {
  var overlayStyle = {
    position: 'absolute',
    width: '100%',
    left: '0',
    top: '0',
    pointerEvents: 'none',
  };
  var iconContainerStyle = {
    position: 'absolute',
    left: '' + iconPos.x + '%',
    top: '' + iconPos.y + '%',
  };
  var iconBackgroundStyle = {
    position: 'relative',
    left: '-1.25vmax',
    top: '-1.25vmax',
    width: '2.7vmax',
    height: '2.7vmax',
    background: background ? background : 'rgb(50, 100, 255)',
    borderRadius: '7px',
    boxShadow: '0 5px 20px 2px black',
    cursor: 'pointer',
    textAlign: 'center',
  };
  var iconStyle = {
    color: 'white',
    fontSize: '2.2vmax',
    marginTop: '0.3vmax',
  };
  var fragment = '#regionId:' + regionId;
  return {
    id: regionId,
    image: '/images/' + regionId + '.png',
    style: overlayStyle,
    icon: <div style={iconContainerStyle} key={regionId + '-icon'}>
            <div style={iconBackgroundStyle}>
              <a href={fragment} onClick={() => regionClick(regionId)} title={text}>
                <i style={iconStyle} className={icon} />
              </a>
            </div>
          </div>,
    tooltip: {
      text: text,
      pos: tooltipPos
    },
  };
};

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.regionClick = this.regionClick.bind(this)

    var regions = [
      Region(this.regionClick, {x: 37, y: 21}, 'fas fa-subway', 'hsa-newStation',
        {x: 37, y: 33}, "Construction site for the new Hillsdale Caltrain Station", null),
      Region(this.regionClick, {x: 63, y: 9}, 'fas fa-subway', 'hsa-oldStation',
        {x: 70, y: 14}, "The existing Hillsdale Caltrain Station. Currently closed.", null),
      Region(this.regionClick, {x: 39, y: 40}, 'fas fa-ticket-alt', 'hsa-theater',
        {x: 39, y: 47}, "New Cinepolis luxury movie theater", null),
      Region(this.regionClick, {x: 7.5, y: 5}, 'fas fa-hard-hat', '25th-ave',
        {x: 10, y: 15}, "25th Avenue underpass", '#d33'),
      Region(this.regionClick, {x: 50, y: 8}, 'fas fa-traffic-light', '28th-ave',
        {x: 50, y: 15}, "28th Avenue underpass, opening March 1st", '#4b4'),
      Region(this.regionClick, {x: 82, y: 9}, 'fas fa-traffic-light', '31st-ave',
        {x: 82, y: 18}, "31th Avenue underpass & S. Delaware St.", '#4b4'),
      // TODO: Add Hillsdale Shopping Center
    ];

    this.state = {
      selectedOverlay: null,
      regions: regions,
    }
  }

  regionClick(regionId) {
      if (typeof history != 'undefined') {
      if (regionId != null) {
        history.replace({}, '#regionId:' + regionId);
      } else {
        history.replace({}, '#');
      }
      this.setState({
        selectedOverlay: regionId,
      });
      }
  }

  componentDidMount() {
    // Pick selected region from fragment, if it exists
    if (typeof window != 'undefined' && window.location.hash && window.location.hash.indexOf('#regionId:') === 0) {
      var fragmentRegionId = window.location.hash.substring('#regionId:'.length);
      this.state.regions.forEach(function(region) {
        if (fragmentRegionId === region.id) {
          this.setState({
            selectedOverlay: fragmentRegionId,
          });
        }
      }, this);
    }
  }

  render() {
    var mapStyle = {
      position: 'relative',
      userSelect: 'none',
    };
    var icons = [];
    var overlays = [];
    var tooltips = [];
    this.state.regions.forEach(function(region) {
      var selected = this.state.selectedOverlay === region.id;
      var style = Object.assign({}, region.style, {display: selected ? 'inline' : 'none'});
      var tooltipStyle = {display: selected ? 'inline' : 'none'};
      if (!selected) {
        icons.push(region.icon);
      }

      // Always add all overlays to make sure they get prefetched
      overlays.push(
      <button style={{display: 'contents'}} onClick={() => this.regionClick(null)} onKeyDown={() => this.regionClick(null)}>
        <img key={region.id} src={region.image} alt="" style={style} />
      </button>);
      // Always add all tooltips for Seo
      tooltips.push(<Tooltip key={region.id + '-tooltip'} style={tooltipStyle} text={region.tooltip.text} pos={region.tooltip.pos} />);
    }, this);
    return (
      <div style={contentSpacerStyle}>
        <h2>Hillsdale Station Area Map</h2>
        <div style={mapStyle}>
          {icons}
          {overlays}
          {tooltips}
          <button style={{display: 'contents'}} onClick={() => this.regionClick(null)} onKeyDown={() => this.regionClick(null)}>
            <img alt="map" src="/images/HillsdaleStationArea.png" style={{width: '100%'}} />
          </button>
        </div>
      </div>
    )
  }
}

class Content extends React.Component {
  render() {
    var itemStyle = {
      marginTop: '4px',
      display: 'flex',
    };

    var itemImageStyle = {
      width: '120px',
      height: '120px',
      marginRight: '6px',
      borderRadius: '5px',
    };

    var itemDateStyle = {
      fontWeight: 'bold',
      marginBottom: '4px',
    };

    var news = [
      {
        image: '/images/caltrainLogo.png',
        imageTitle: "Hillsdale station now open!",
        date: 'April 26th',
        content: (<div>The new Hillsdale Caltrain station is now open!</div>),
      },
      {
        image: '/images/caltrainLogo.png',
        imageTitle: "New Hillsdale station to open April 26th!",
        date: 'April 19th',
        content: (<div>Caltrain announced today that the new station will officially open on April 26th. The new station is designed with a center platform so riders will no longer be required to cross the tracks to switch platforms.</div>),
      },
      {
        image: '/images/caltrainLogo.png',
        imageTitle: "28th Avenue underpass is now open!",
        date: 'March 1st',
        content: (<div>Caltrain announced today that the 28th Avenue underpass is now open! Drivers are encouraged to use the new underpass as an alternative route to the 25th Avenue underpass, as construction continues there.</div>),
      },
      {
        image: '/images/caltrainLogo.png',
        imageTitle: "28th Avenue underpass to open on March 1st",
        date: 'Feb 26th',
        content: (<div>Caltrain announced today that new fencing will go up this weekend around the 28th Avenue underpass. This east-west connection under the railroad is expected to serve auto and pedestrian traffic starting March 1st.</div>),
      },
      {
        image: '/images/feb11-25th-closure.png',
        imageTitle: "A section of 25th Avenue to close on March 1st",
        date: 'Feb 11th',
        content: (<div>East-bound on 25th from Palm Ave to S. Delaware St. will close starting March 1st and will be closed for about 4 months. The west-bound of the same section will close in late March. Plan accordingly!</div>),
      },
      {
        image: '/images/caltrainLogo.png',
        imageTitle: "New storm drain lines being installed",
        date: 'Feb 5th',
        content: (<div>Caltrain has begun installing new storm drain lines at and around 31st Avenue. Progress on the 28th Avenue underpass continues.</div>),
      },
      {
        image: '/images/caltrainLogo.png',
        imageTitle: "Caltrain reaffirms Hillsdale station opening",
        date: 'Dec 17th',
        content: (<div>Caltrain is anticipating a <b>Spring 2021 opening</b> for the new Hillsdale station. Irrigation and painting have begun at the new 28th Avenue crossing.</div>),
      },
      {
        image: '/images/caltrainLogo.png',
        imageTitle: "Caltrain May 11th announcement",
        date: 'May 11th',
        content: (<div>Caltrain announced today that work on E. 25th Avenue is continuing.  However, parking will be limited.  Businesses will remain open during the construction.  Also mentioned in the announcement was the start of PG&E work.  It wasn't clear if this work would impact electrical and gas utilities in the area.</div>),
      },
      {
        image: '/images/parkingSign1.jpg',
        imageTitle: "Caltrain to setup temporary parking as construction continues",
        date: 'Apr. 22nd',
        content: (<div>April 23rd to 27th, Caltrain construction crews will be setting up temporary parking at Delaware and Pacific Blvd. in preparation for continued construction.  In addition, there will be excavation work done at 31st Ave. and north of 28th Ave.</div>),
      },
    ];

    var newsItems = news.map(function(n, index) {
      return (<li style={itemStyle} key={index}>
        <div><a href={n.image}>
          <img src={n.image} style={itemImageStyle} title={n.imageTitle} alt={n.imageTitle} />
        </a></div>
        <div>
          <div style={itemDateStyle}>{n.date}</div>
          <div>{n.content}</div>
        </div>
      </li>)
    });

    return (
      <div>
<p>
  Back in 2013, the City of San Mateo applied for funds to improve the Caltrain station in Hillsdale.  The improvements have been underway since <a href="http://www.caltrain.com/Assets/_Public+Affairs/Capital_Program/25t/25th+GS+Night+Work+Overview.pdf" title="Caltrain 25th Avenue Grade Separation Project Document">mid-2017</a> and are expected to complete in early 2021.
  Also under construction was the famous Hillsdale Shopping Center.  Around mid-2019, construction completed and added almost double the number of shops, including a luxury cinema!  If you're interested in reading more about shopping, see the <a href="http://hillsdale.com/" title="Hillsdale Shopping Mall">official website</a>.
</p>
<p>
  Note: The current Hillsdale Station is <b>CLOSED</b> as of early 2019 in order to complete the transition for the new station.  Plan accordingly and check back here for updates!
</p>
<p>
  For more information regarding the Caltrain station construction, see the official plan document: <a href="https://www.cityofsanmateo.org/documentcenter/view/59484" title="Hillsdale Station Area Plan">Hillsdale Station Area Plan</a>
</p>

<p>
<h1>Current Project Status</h1>
<table style={{fontSize: '18px', borderCollapse: 'collapse'}}>
  <tr style={{background: '#efe'}}>
    <td style={{width: '30%'}}>Hillsdale Station</td>
    <td style={{color: '#5a5', textAlign: 'center', fontSize: '30px'}}><i class="fas fa-subway" /></td>
    <td style={{color: '#5a5'}}>Open as of April 26th!</td>
  </tr>
  <tr style={{background: '#ffd'}}>
    <td>25th Avenue Underpass</td>
    <td style={{color: '#aa0', textAlign: 'center', fontSize: '30px'}}><i class="fas fa-exclamation-triangle" /></td>
    <td style={{color: '#aa0'}}>Construction in progress, but accessible to vehicle traffic going west-bound only</td>
  </tr>
  <tr style={{background: '#efe'}}>
    <td>28th Avenue Underpass</td>
    <td style={{color: '#5a5', textAlign: 'center', fontSize: '30px'}}><i class="fas fa-traffic-light" /></td>
    <td style={{color: '#5a5'}}>Accessible to vehicle and foot traffic as of March 1st!</td>
  </tr>
  <tr style={{background: '#fee'}}>
    <td>31st Avenue & S. Delaware St.</td>
    <td style={{color: '#a55', textAlign: 'center', fontSize: '30px'}}><i class="fas fa-hard-hat" /></td>
    <td style={{color: '#a55'}}>Under construction. Blocked to all traffic.</td>
  </tr>
</table>
</p>

<h1>Latest News</h1>
<ul style={{listStyle: 'none', padding: '0'}}>{newsItems}</ul>
      </div>
    )
  }
}

const IndexPage = () => (
  <>
    <Seo />
    <div className="content">
      <Content />
      <Map />
    </div>
  </>
)

export default IndexPage
